<template>
  <base-section id="menu" space="0">
    <SlideCategories @updateListProduct="updateListProduct" />

    <BannerText />

    <v-container class="px-8">
      <base-section v-if="products.length > 0 && !loadingContent">
        <content-card-list
          :products="products"
          :currentCategoryId="currentCategoryId"
        />
        <div style="position: fixed; bottom: 15px; right: 15px; z-index: 1">
          <v-badge
            :value="$store.getters['cart/totalQty'] > 0"
            offset-x="10"
            offset-y="10"
            color="primary"
            :content="$store.getters['cart/totalQty']"
          >
            <v-btn
              fab
              elevation="3"
              small
              :large="$vuetify.breakpoint.mdAndUp"
              to="/carrito"
            >
              <v-icon :large="$vuetify.breakpoint.mdAndUp" color="black">
                mdi-cart-outline
              </v-icon>
            </v-btn>
          </v-badge>
        </div>
      </base-section>

      <base-section v-else space="0">
        <v-row v-if="loadingContent" justify="center">
          <v-progress-circular
            :width="15"
            :size="100"
            color="rgba(184, 184, 184,0.4)"
            indeterminate
          />
        </v-row>
        <v-row v-else justify="center">
          <v-col cols="12" class="text-center">
            <v-icon size="120" class="mx-auto mb-6" color="primary lighten-2">
              mdi-cart-off
            </v-icon>
          </v-col>

          <v-col cols="12">
            <base-subheading
              class="text-center text--disabled"
              :title="$t('productsNotFound')"
            />
          </v-col>
        </v-row>
      </base-section>
    </v-container>
  </base-section>
</template>

<script>
import { productsCtrl } from "@/controllers";
export default {
  name: "MenuSection",

  provide() {
    return {
      heading: { align: "center" },
    };
  },

  data() {
    return {
      loadingContent: true,
      products: [],
      currentCategoryId: null,
    };
  },

  components: {
    BannerText: () => import("@/components/BannerText.vue"),
    ContentCardList: () => import("@/components/ContentCardList.vue"),
    SlideCategories: () =>
      import("@/components/categories/SlideCategories.vue"),
  },

  methods: {
    updateListProduct(category) {
      this.loadingContent = true;
      this.currentCategoryId = category.id;
      this.getProductsByCategory();
    },
    getProductsByCategory() {
      productsCtrl
        .getProductsByCategory(this.currentCategoryId)
        .then((products) => {
          this.products = products;
        })
        .finally(() => (this.loadingContent = false));
    },
  },
};
</script>
<style lang="sass" scoped>
::v-deep .no-active
  background-color: transparent !important
  opacity: 1 !important
  border: 0.17rem solid var(--v-primary-base)
  > span
    color: var(--v-primary-base)
</style>
